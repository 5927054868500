<template>
  <ConfirmModalContainer>
    <template #default="{ opened, question, okLabel, cancelLabel, reject, accept }">
      <BModal
        v-if="opened"
        visible
        centered
        modal-class="confirm-box"
        @hidden="reject"
        @close="reject"
        @ok="accept"
      >
        <template #modal-title>Conferma necessaria</template>

        <template #modal-footer>
          <button @click.prevent="reject" class="button-reject btn btn-secondary">
            {{ cancelLabel }}
          </button>

          <button @click.prevent="accept" class="button-accept btn btn-primary">
            {{ okLabel }}
          </button>
        </template>

        {{ question }}
      </BModal>
    </template>
  </ConfirmModalContainer>
</template>

<script>

import ConfirmModalContainer from './ConfirmModalContainer.vue';
import { BModal } from 'bootstrap-vue';

export default {
  components: {
    BModal,
    ConfirmModalContainer,
  },
};

</script>
